import type { Meta, Objects } from '~/types'
import { FilterTabType } from '~/enums'

interface State {
  items: Objects[]
  meta: Meta
  page: number
  count: number
  pending: boolean
  pendingList: boolean
  pendingMap: boolean
  isLoaded: boolean
  itemsLoadedServer: boolean
  itemsLoadedClient: boolean
}

const makeTags = (params: any, fields: any) => {
  const tags = []

  for (const field of fields) {
    if (field.length > 0) {
      tags.push(field.map((t: any) => t.id))
    }
  }

  return tags
}

const makeQuery = (params: any) => {
  const filterStore = useFilterStore()

  const query = {} as any

  const tags = makeTags(params, [
    params.highway,
  ])
  if (tags.length > 0) {
    query['filter[tags]'] = tags.join(',')
  }

  const tagsStrict = makeTags(params, [
    params.communications,
    params.infrastructures,
    params.heating
  ])
  if (tagsStrict.length > 0) {
    query['filter[tags_strict]'] = tagsStrict.join(',')
  }

  const types = makeTags(params, [
    params.types,
  ])
  if (types.length > 0) {
    query['filter[tags_types]'] = types.join(',')
  }

  const purposeLands = makeTags(params, [
    params.purpose_lands,
  ])
  if (purposeLands.length > 0) {
    query['filter[tags_purpose_lands]'] = purposeLands.join(',')
  }

  const tagsMaterials = makeTags(params, [
    params.materials,
  ])
  if (tagsMaterials.length > 0) {
    query['filter[tags_materials]'] = tagsMaterials.join(',')
  }

  const tagsInteriorDecorations = makeTags(params, [
    params.interior_decorations,
  ])
  if (tagsInteriorDecorations.length > 0) {
    query['filter[tags_interior_decorations]'] = tagsInteriorDecorations.join(',')
  }

  const tagsFurniture = makeTags(params, [
    params.furniture,
  ])
  if (tagsFurniture.length > 0) {
    query['filter[tags_furniture]'] = tagsFurniture.join(',')
  }

  if (params.mkad.from) {
    query['filter[mkad][from]'] = params.mkad.from
  }
  if (params.mkad.to) {
    query['filter[mkad][to]'] = params.mkad.to
  }

  if (params.area.plot.from) {
    query['filter[plot_area][from]'] = params.area.plot.from
  }

  if (params.area.plot.to) {
    query['filter[plot_area][to]'] = params.area.plot.to
  }

  if (params.area.house.from) {
    query['filter[house_area][from]'] = params.area.house.from
  }

  if (params.area.house.to) {
    query['filter[house_area][to]'] = params.area.house.to
  }

  if (params.price.from) {
    query['filter[price][from]'] = params.price.from
  }

  if (params.price.to) {
    query['filter[price][to]'] = params.price.to
  }

  if (params.search) {
    query['filter[search]'] = params.search;
  }

  if (params.page && parseInt(params.page) > 1) {
    query['page'] = params.page
  }

  if (filterStore.tab === FilterTabType.Map) {
    query['map'] = true
  }

  query['perPage'] = 20

  return query
}

export const useObjectStore = defineStore('object', {
  state: (): State => {
    return <State>{
      items: [],
      meta: {} as Meta,
      page: 1,
      count: 0,
      pending: false,
      pendingList: false,
      pendingMap: false,
      isLoaded: false,
      itemsLoadedServer: false,
      itemsLoadedClient: false,
    }
  },
  getters: {
    itemsLoaded: (state: State) => {
      return state.itemsLoadedServer && state.itemsLoadedClient
    },
  },
  actions: {
    list(params: any){
      const query = makeQuery(params)

      return useAPIFetch<{ data: Objects[], meta: Meta }>('/v1/objects/search', {
        query
      })
    },
    async search(params: any){
      this.pendingList = true

      const query = makeQuery(params)

      const result = await useAPIFetch<{ data: Objects[], meta: Meta }>('/v1/objects/search', {
        query
      })

      this.pendingList = false

      this.items = result.data
      this.meta = result.meta

      import.meta.server
        ? this.itemsLoadedServer = true
        : this.itemsLoadedClient = true
    },
    searchVillage(params: any){
      const query = makeQuery(params)

      return useAPIFetch<{ data: Objects[], meta: Meta }>('/v1/objects/search/village', {
        query
      })
    },
    async searchMap(params: any){
      this.pendingMap = true

      const query = makeQuery(params)

      const result = await useAPIFetch<{ data: Objects[], meta: Meta }>('/v1/objects/search/map', {
        query
      })

      this.pendingMap = false

      this.items = result.data
      this.meta = result.meta
    },
    async searchCount(params: any){
      this.pending = true

      const query = makeQuery(params)

      const result = await useAPIFetch<{ count: number }>('/v1/objects/search/count', {
        query
      })

      this.pending = false

      this.count = result.count
    },
    show(id: number) {
      return useAPIFetch<Objects>('/v1/objects/' + id, {
        method: 'GET',
      })
    },
  }
})
